<template>
  <div class="similar-goods" v-if="goods">
    <div class="main-card">
      <div class="active-goods">
        <div class="active-left">
          <div>
            <el-image
              style="width: 160px; height: 160px; margin-right: 20px"
              :src="goods.image"
              fit="fit"
            ></el-image>
          </div>
          <div style="width: 650px">
            <div style="font-size: 18px">
              {{ goods.title }}
            </div>
            <div style="color: #929292; margin-top: 10px">
              <el-rate
                style="display: inline"
                v-model="goods.score"
                disabled
                :colors="['#ee4d2d', '#ee4d2d', '#ee4d2d']"
                score-template="{value}"
              >
              </el-rate>
              <span>{{ goods.score.toFixed(1) }}/5</span>
              <span style="margin: 0 10px">({{ goods.comment }}个评价)</span>
              <el-divider direction="vertical"></el-divider>
              <span>销量 {{ goods.sales }}</span>
            </div>
            <div style="font-size: 28px; color: #ee4d2d; margin: 10px 0">
              {{ goods.price }}
            </div>
            <div>
              <el-button
                size="medium"
                type="primary"
                @click="toGoodsInfo(goods.id)"
                >立刻前往购买</el-button
              >
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="active-right">
          <div style="display: flex; align-items: center">
            <div style="margin-right: 10px">
              <el-avatar
                size="medium"
                :src="goods.shop.shop_avatar"
              ></el-avatar>
            </div>
            <div>{{ goods.shop.shop_name }}</div>
          </div>
          <div
            style="
              margin-top: 18px;
              font-size: 14px;
              color: #929292;
              display: flex;
              align-items: center;
            "
          >
            <span style="margin-right: 20px">
              商品<span style="color: #ee4d2d; margin-left: 6px">{{
                goods.shop.goods_num
              }}</span></span
            >
            <el-divider direction="vertical"></el-divider>
            <span style="margin-left: 20px">
              评价数
              <span style="color: #ee4d2d; margin-left: 6px">{{
                goods.shop.comment
              }}</span></span
            >
          </div>
        </div>
      </div>
      <div class="same-goods">
        <div style="font-size: 20px; margin: 20px 0 10px">相似商品</div>
        <div class="goods-list">
          <div v-for="item in goodsList.list" :key="item.id">
            <HomeGoods :data="item" :type="false"></HomeGoods>
          </div>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px 0;
          "
        >
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="page_size"
            :current-page="page"
            @current-change="handlePage"
            :total="goodsList.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getSimilarGoods, goodsDetail } from "@/api/home.js";
import HomeGoods from "@/components/goods/HomeGoods";
export default {
  components: {
    HomeGoods,
  },
  data() {
    return {
      url: "",
      value: 1,
      goods: "",
      goodsList: [],
      page: 1,
      page_size: 50,
    };
  },
  created() {
    this.getSomeGoods();
  },
  methods: {
    getSomeGoods() {
      goodsDetail({
        goods_id: this.$route.query.goodsId,
      }).then((res) => {
        if (res.code == 1) {
          this.goods = res.data;
          this.getGoods();
        }
      });
    },
    getGoods() {
      getSimilarGoods({
        category_id: this.goods.category_id,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        if (res.code == 1) {
          this.goodsList = res.data;
        }
      });
    },
    toGoodsInfo(id) {
      this.$router.push({ path: "goodsInfo", query: { goodsId: id } });
    },
    handlePage(val) {
      this.page = val;
      this.getGoods();
    },
  },
};
</script>
<style lang="less" scoped>
.similar-goods {
  background: #f5f5f5;
  padding: 20px 0 30px;

  .active-goods {
    width: 100%;
    padding: 25px 30px;
    background: #fff;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    .active-left {
      display: flex;
    }
    .line {
      height: 160px;
      width: 1px;
      background: #eee;
    }
    .active-right {
      width: 254px;
    }
  }
  .same-goods {
    .goods-list {
      display: flex;
    }
  }
}
</style>